<template>
  <section class="desgin">
    <Loader :loading="showLoader" />
    <div class="row flex-grow">

      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">About Section </h4>
            <form @submit.prevent="editHomePage" class="forms-sample">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="homepage.aboutTitle" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Description</label>
                    <div>
                      <textarea v-model="homepage.aboutDescription" class="form-control" rows="7"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Avant Garde Residences</h4>
            <form @submit.prevent="editHomePage" class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="homepage.avantgardeTitle" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Description</label>
                    <div>
                      <textarea v-model="homepage.avantgardeDescription" class="form-control" rows="15"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Image</label>
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="avantGardeImgUpload">
                      <div class="dropzone-custom-content">
                        <div class="image-size-dropzone">
                          <div>
                            <img :src="`${imageURL}/${homepage.avantgardeImage}`" class="show-img img-fluid" />
                          </div>
                        </div>
                        <div class="subtitle p-2">
                          <span>Change</span>
                          <span style="color: #eb2027"> Image</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Design Section </h4>
            <form @submit.prevent="editHomePage" class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="homepage.designTitle" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Description</label>
                    <div>
                      <textarea v-model="homepage.designDescription" class="form-control" rows="15"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Image</label>
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="designImgUpload">
                      <div class="dropzone-custom-content">
                        <div class="image-size-dropzone">
                          <div>
                            <img :src="`${imageURL}/${homepage.designImage}`" class="show-img img-fluid" />
                          </div>
                        </div>
                        <div class="subtitle p-2">
                          <span>Change</span>
                          <span style="color: #eb2027"> Image</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Amenities Section </h4>
            <form @submit.prevent="editHomePage" class="forms-sample">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="homepage.amenitiesTitle" class="form-control">
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <label class="d-block">Description</label>
                    <div>
                      <textarea v-model="homepage.amenitiesDescription" class="form-control" rows="15"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <label class="d-block">Image</label>
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="amenitiesImgUpload">
                      <div class="dropzone-custom-content">
                        <div class="image-size-dropzone">
                          <div>
                            <img :src="`${imageURL}/${homepage.amenitiesImage}`" class="show-img img-fluid" />
                          </div>
                        </div>
                        <div class="subtitle p-2">
                          <span>Change</span>
                          <span style="color: #eb2027"> Image</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Loader from "../components/Loader";
import { sliderApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;
export default {
  name: 'homepage',
  components: {
    vueDropzone: vue2Dropzone, Loader,
  },
  data() {
    return {
      showLoader: false,
      imageURL,
      homepage: "",
      thumb: {
        url: `${imageURL}/upload`,
        maxFilesize: 1,
      },
    };
  },
  mounted() {
    this.HomePageById();
  },
  methods: {

    async HomePageById() {
      this.showLoader = true;
      const { data } = await sliderApi.getHomePageById(`1`);
      this.homepage = data.data.data;
      this.showLoader = false;
    },

    amenitiesImgUpload(file, response) {
      this.homepage.amenitiesImage = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    avantGardeImgUpload(file, response) {
      this.homepage.avantgardeImage = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    designImgUpload(file, response) {
      this.homepage.designImage = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async editHomePage() {
      this.showLoader = true;
      const data = await sliderApi.updateHomePage(this.homepage._id, {
        ...this.homepage,
      });
      this.HomePageById();
      this.showLoader = false;
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },
  }
}
</script>
